import { Row, Col } from 'components/grid'
import React from 'react'

export default function SocialMedia() {
	return (
		<Row>
			<Col width="w-full md:w-1/3" className="mx-auto mt-6 flex justify-center gap-4">
				<a href="https://www.facebook.com/americanreading/" aria-label="Visit our facebook profile" target="_blank">
					<svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 text-[#4A6BA4] hover:text-[#2C58A4] lg:h-16 lg:w-16" viewBox="0 0 24 24" fill="currentColor">
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm3 8h-1.35c-.538 0-.65.221-.65.778v1.222h2l-.209 2h-1.791v7h-3v-7h-2v-2h2v-2.308c0-1.769.931-2.692 3.029-2.692h1.971v3z"
						/>
					</svg>
				</a>
				<a href="https://twitter.com/AmericanReading" aria-label="Visit our twitter profile" target="_blank">
					<svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 text-[#06B2F7] hover:text-[#00A2E5] lg:h-16 lg:w-16" viewBox="0 0 24 24" fill="currentColor">
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"
						/>
					</svg>
				</a>
				<a href="https://www.youtube.com/channel/UClr82wkKDNGNkDV9WfBszGg" aria-label="Visit our youtube page" target="_blank">
					<svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 text-[#FF2600] hover:text-[#E72201] lg:h-16 lg:w-16" viewBox="0 0 24 24" fill="currentColor">
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z"
						/>
					</svg>
				</a>
				<a href="https://www.linkedin.com/company/american-reading-company/" aria-label="Visit our linkedin profile" target="_blank">
					<svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 text-[#0086BC] hover:text-[#0076A5] lg:h-16 lg:w-16" viewBox="0 0 24 24" fill="currentColor">
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"
						/>
					</svg>
				</a>
			</Col>
		</Row>
	)
}
