import ARCLoves from 'components/home/ARCLoves'
import JoinARC from 'components/home/JoinARC'
import MonthlyWebinar from 'components/home/MonthlyWebinar'
import SocialMedia from 'components/home/SocialMedia'
import Layout from 'components/Layout'
import Slider from 'components/slider'
import React from 'react'

import { Col, Row, Section } from '../components/grid'
import ARCCore from '../components/home/ARCCore'
import LargeForm from 'components/forms/LargeForm'

export default function HomePage() {
	return (
		<Layout pageName="Home">
			<Section>
				<Row className="mt-10">
					<Slider name="Home Page" />
				</Row>
			</Section>
			<Section margin="mt-20">
				<ARCCore />
			</Section>
			<Section margin="mt-20" flatGray>
				<ARCLoves />
			</Section>
			<Section margin="mt-20">
				<Row>
					<Col width="w-full md:w-1/2" className="mb-6">
						<MonthlyWebinar />
					</Col>
					<Col width="w-full md:w-1/2" className="mb-6">
						<h3 className="mb-3">Connect with us</h3>
						<LargeForm pageName="Home Page" />
					</Col>
				</Row>
			</Section>
			<Section margin="mt-20">
				<JoinARC />
				<SocialMedia />
			</Section>
		</Layout>
	)
}
