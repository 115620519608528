import { Col, Row } from 'components/grid'
import ReviewsResults from 'components/state-reviews/ReviewsResults'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

export default function ARCCore() {
	return (
		<Row className="border-4 border-ab-50" overflow={false}>
			<Col className="flex justify-center">
				<div className="-mt-32">
					<Link to="/arc-core">
						<StaticImage data-cy="arc-core-logo" src="../../../static/images/logos/arc-core-logo-halo.png" alt="arc core logo" className="max-w-lg" />
					</Link>
				</div>
			</Col>
			<Col width="w-full md:w-3/4" className="mx-auto -mb-10 text-center">
				<p data-cy="arc-core-copy">
					Grounded in the science of reading, ARC Core is a K–12 high-quality curriculum that combines systematic, evidence-based skills instruction with
					knowledge-building in science, social studies, and literature. It is available in English and Spanish and is designed to dramatically improve outcomes for both
					students and teachers.
				</p>
			</Col>
			<Col>
				<ReviewsResults />
				<span data-cy="state-reviews-copy" className="block text-center text-xl font-semibold text-slate-800">
					You can also see our{' '}
					<Link data-cy="state-reviews-link" to="/state-reviews">
						state-level reviews
					</Link>{' '}
					on ARC solutions.
				</span>
			</Col>
			<Col className="mt-8 mb-10 flex justify-center">
				<Link to="/arc-core/#watchItInAction" className="hover:no-underline">
					<button data-cy="watch-it-in-action-button" className="flex items-center rounded-full bg-ar-100 px-6 py-3 text-2xl font-semibold text-white hover:bg-ar-200">
						<svg xmlns="http://www.w3.org/2000/svg" className="mr-3 h-8 w-8 text-white" viewBox="0 0 20 20" fill="currentColor">
							<path
								fillRule="evenodd"
								d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
								clipRule="evenodd"
							/>
						</svg>
						Watch It in action
					</button>
				</Link>
			</Col>
		</Row>
	)
}
