import { Row, Col } from 'components/grid'
import { Link, graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, IGatsbyImageData, StaticImage } from 'gatsby-plugin-image'
import React from 'react'

export default function JoinARC() {
	const data = useStaticQuery<Query>(query).allContentfulAsset.edges[0].node.gatsbyImageData
	return (
		<Row>
			<Col>
				<Link data-cy="careers-banner" to="/about-us" className="hover:no-underline">
					<div className="relative">
						<GatsbyImage image={data} alt="arc careers collage" />
						<div className="absolute top-0 left-0 flex h-full w-full items-center justify-center">
							<div>
								<h1 className="text-center text-5xl text-white lg:text-6xl xl:text-8xl" style={{ textShadow: '3px 3px 5px rgba(0, 0, 0, 0.4)' }}>
									Join ARC
								</h1>
								<p
									className="text-center text-lg font-semibold leading-tight text-white lg:text-2xl xl:text-3xl"
									style={{ textShadow: '1.5px 1.5px 3px rgba(0, 0, 0, 0.4)' }}
								>
									Visit our careers page to learn more.
								</p>
							</div>
						</div>
					</div>
				</Link>
			</Col>
		</Row>
	)
}

interface Query {
	allContentfulAsset: {
		edges: {
			node: { gatsbyImageData: IGatsbyImageData }
		}[]
	}
}

const query = graphql`
	query {
		allContentfulAsset(filter: { title: { eq: "Rainbow Collage" } }) {
			edges {
				node {
					gatsbyImageData
				}
			}
		}
	}
`
