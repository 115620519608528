import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import React from 'react'

export default function MonthlyWebinar() {
	const query = useStaticQuery<{ image: { gatsbyImageData: IGatsbyImageData } }>(graphql`
		query {
			image: contentfulAsset(title: { eq: "Monthly Webinar" }) {
				gatsbyImageData(placeholder: BLURRED)
			}
		}
	`)
	const image = query.image.gatsbyImageData

	return (
		<div>
			<h3 className="mb-3">Learn with us</h3>
			<a data-cy="monthly-webinar-link" href="https://info.americanreading.com/upcoming-webinar" target="_blank" rel="noreferrer">
				<GatsbyImage data-cy="monthly-webinar-image" image={image} alt="monthly webinar details" className="shadow-md" />
			</a>
		</div>
	)
}
