import 'react-responsive-carousel/lib/styles/carousel.min.css'

import Card from 'components/arc-loves/Card'
import PostModal from 'components/arc-loves/PostModal'
import { Button } from '@arckit/ui'
import { Col, Row } from 'components/grid'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { ARCLoves as IARCLoves, ARCLovesQuery } from 'pages/arc-loves'
import React, { useState } from 'react'

export default function ARCLoves() {
	const query = useStaticQuery<ARCLovesQuery>(graphql`
		query {
			allContentfulArcLoves(filter: { homePage: { eq: true } }) {
				edges {
					node {
						id
						name
						title
						type
						location
						featured
						homePagePosition
						publishedDate
						sliderImages {
							id
							gatsbyImageData(height: 975)
						}
						photo {
							title
							gatsbyImageData(height: 355)
						}
						link
						video {
							title
							thumbnail {
								gatsbyImageData(height: 975)
							}
						}
						tags
						createdAt
					}
				}
			}
		}
	`)
	const posts = query.allContentfulArcLoves.edges

	const [post, setPost] = useState<IARCLoves | null>(null)
	const [isModalOpen, setIsModalOpen] = useState(false)

	const handleModalClose = () => setIsModalOpen(false)

	const handleModalContent = (id: string) => {
		const { node: post } = posts.filter((d) => d.node.id === id)[0]
		setPost(post)
		setIsModalOpen(true)
	}
	return (
		<div className="py-20">
			<Row>
				<Col width="w-full md:w-1/3" className="mx-auto">
					<Link to="/arc-loves">
						<div className="bg-ar-100 p-6">
							<StaticImage src="../../../static/images/logos/arc-loves.png" alt="arc loves logo" />
						</div>
					</Link>
				</Col>
			</Row>
			<Row testId="arc-loves-post-container" className="mt-12">
				{posts
					.slice(0, 3)
					.sort((a, b) => {
						// @ts-ignore
						if (a.node?.homePagePosition > b.node?.homePagePosition) return 1
						else return -1
					})
					.map((item) => (
						<Col key={item.node.id} width="w-full md:w-1/2 lg:w-1/3" className="mb-12">
							<Card data={item.node} handleModalContent={handleModalContent} />
						</Col>
					))}
			</Row>
			<Row>
				<Col className="flex justify-center">
					<Link data-cy="arc-loves-see-more-button" to="/arc-loves">
						<Button size="lg">More ARC Loves Posts</Button>
					</Link>
				</Col>
			</Row>
			<PostModal post={post} isModalOpen={isModalOpen} handleModalClose={handleModalClose} />
		</div>
	)
}
